import { create } from '@storybook/theming/create';

import packageJson from '../package.json';

let version = `v${packageJson.version}`;

try {
  if (process.env.STORYBOOK_CI === 'true') {
    const isRelease = process.env.STORYBOOK_IS_RELEASE === 'true';
    version = isRelease
      ? `${version}`
      : `${version}-${process.env.STORYBOOK_SOURCE_COMMIT}`;
  } else {
    version = `${version}-local`;
  }
} catch {}

export default create({
  base: 'light',

  // Brand
  brandTitle: ["Design Système de l'Autonomie", version]
    .filter(Boolean)
    .join(' – '),
  brandUrl: 'https://dsa.service-public-autonomie.fr',
  brandTarget: '_blank',

  // Theme
  colorPrimary: '#A0DBF4',
  colorSecondary: '#540ABD',

  appBg: '#F7F7F7',
  appContentBg: '#ffffff',
  // appBorderColor?: string;
  // appBorderRadius?: number;
  //
  // fontBase?: string;
  // fontCode?: string;
  //
  textColor: '#353635',
  // textInverseColor?: string;
  textMutedColor: '#454745',
  //
  barTextColor: '#353635',
  barSelectedColor: '#540ABD',
  barBg: '#F7F7F7',
  //
  // buttonBg?: string;
  // buttonBorder?: string;
  //
  // booleanBg?: string;
  // booleanSelectedBg?: string;
  //
  // inputBg?: string;
  // inputBorder?: string;
  // inputTextColor?: string;
  // inputBorderRadius?: number;
  //
  // gridCellSize?: number;
});
