{
  "name": "@ds-autonomie/web-components",
  "version": "1.21.0",
  "author": "CNSA",
  "license": "MIT",
  "customElements": "dist/custom-elements.json",
  "web-types": "dist/web-types.json",
  "type": "module",
  "types": "dist/design-system.d.ts",
  "exports": {
    ".": {
      "types": "./dist/design-system.d.ts",
      "import": "./dist/design-system.js"
    },
    "./dist/custom-elements.json": "./dist/custom-elements.json",
    "./dist/themes/*": "./dist/themes/*",
    "./dist/components/*": "./dist/components/*",
    "./dist/utilities/*": "./dist/utilities/*",
    "./dist/translations/*": "./dist/translations/*"
  },
  "files": [
    "dist",
    "CHANGELOG.md",
    "LICENSE"
  ],
  "repository": {
    "directory": "packages/web-components",
    "type": "git",
    "url": "https://github.com/cnsa-fr/design-system.git"
  },
  "publishConfig": {
    "access": "public"
  },
  "storybook": {
    "url": "https://stable--641b0785d6e1bb53ae448dc7.chromatic.com"
  },
  "scripts": {
    "start": "node scripts/build.js --watch",
    "build": "node scripts/build.js --types",
    "ts:check": "tsc --noEmit --project ./tsconfig.json",
    "test": "web-test-runner --group default",
    "test:coverage": "web-test-runner --group default --coverage",
    "test:component": "web-test-runner -- --watch --group",
    "test:watch": "web-test-runner --watch --group default",
    "storybook": "storybook dev -p 6006",
    "build-storybook": "storybook build"
  },
  "engines": {
    "node": ">=14.17.0"
  },
  "dependencies": {
    "@floating-ui/dom": "1.2.8",
    "@shoelace-style/animations": "1.1.0",
    "@shoelace-style/localize": "3.1.1",
    "big.js": "6.2.2",
    "composed-offset-position": "0.0.4",
    "lit": "2.8.0",
    "wc-datepicker": "0.6.0"
  },
  "peerDependencies": {
    "@ds-autonomie/assets": "workspace:*"
  },
  "devDependencies": {
    "@custom-elements-manifest/analyzer": "0.8.4",
    "@ds-autonomie/eslint-config": "workspace:*",
    "@open-wc/lit-helpers": "0.6.0",
    "@storybook/addon-a11y": "7.3.2",
    "@storybook/addon-actions": "7.3.2",
    "@storybook/addon-backgrounds": "7.3.2",
    "@storybook/addon-controls": "7.3.2",
    "@storybook/addon-docs": "7.3.2",
    "@storybook/addon-links": "7.3.2",
    "@storybook/addon-toolbars": "7.3.2",
    "@storybook/addon-viewport": "7.3.2",
    "@storybook/blocks": "7.3.2",
    "@storybook/manager-api": "7.3.2",
    "@storybook/theming": "7.3.2",
    "@storybook/web-components": "7.3.2",
    "@storybook/web-components-vite": "7.3.2",
    "@types/big.js": "6.2.2",
    "@types/mocha": "10.0.1",
    "@types/react": "18.2.0",
    "@types/sinon": "10.0.13",
    "cem-plugin-vs-code-custom-data-generator": "1.4.2",
    "comment-parser": "1.3.1",
    "download": "8.0.0",
    "eslint-plugin-chai-expect": "3.0.0",
    "eslint-plugin-chai-friendly": "0.7.2",
    "eslint-plugin-import": "2.27.4",
    "eslint-plugin-lit": "1.8.2",
    "eslint-plugin-lit-a11y": "2.3.0",
    "eslint-plugin-markdown": "3.0.0",
    "eslint-plugin-wc": "1.4.0",
    "jsonata": "2.0.1",
    "lunr": "2.3.9",
    "pascal-case": "3.1.2",
    "postcss": "8.4.23",
    "postcss-lit": "1.1.1",
    "react": "18.2.0",
    "react-dom": "18.2.0",
    "sinon": "15.0.1",
    "source-map": "0.7.4",
    "storybook": "7.3.2",
    "storybook-addon-mock-date": "0.6.0",
    "storybook-addon-pseudo-states": "2.1.0",
    "strip-css-comments": "5.0.0",
    "stylelint": "16.5.0",
    "stylelint-value-no-unknown-custom-properties": "6.0.1",
    "ts-dedent": "2.2.0",
    "tslib": "2.6.1",
    "user-agent-data-types": "0.3.0",
    "vite": "4.4.9",
    "vite-plugin-turbosnap": "1.0.2"
  }
}
